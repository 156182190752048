import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import MatchUpCard from "../MatchUpCard/MatchUpCard";
import {MatchUp} from "../../service/matchUps";
import {Grad} from "../../model/Grad";



const PositionCard = ({position, grads, availableGrads, deletePosition, handleSelect, handleRadio}:any) => {
    //we still need to filter out grads that are selected less than the max, but already selected in the position once.
    let gradsUsedInPositionSet = new Set<number>(position.matchUps.map((matchUp: MatchUp)=> matchUp.grad?.id))
    const gradsNotInPosition = availableGrads.filter((grad: Grad) => !gradsUsedInPositionSet.has(grad.id));
    return (
            <Card style={{ width: '25rem', margin: '0.5rem' }}>
                <Card.Header>
                    <span>Position {position.id}</span>
                    <Button variant="danger" size="sm" onClick={() => deletePosition(position.id)}>
                        X
                    </Button>
                </Card.Header>
                <Card.Body>
                    {position.matchUps.sort((a:MatchUp,b:MatchUp) => a.id-b.id).map((matchUp:MatchUp, i:number) => (
                        <MatchUpCard
                            key={matchUp.id}
                            idx={i}
                            matchUp={matchUp}
                            grads={matchUp.grad ? [matchUp.grad, ...gradsNotInPosition] : gradsNotInPosition}
                            handleSelect={handleSelect}
                            handleRadio={handleRadio}
                        />
                    ))}
                </Card.Body>
            </Card>
    )
}

export default PositionCard;