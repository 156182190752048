import { Modal, Alert, Button, Form } from "react-bootstrap";
export const AddGradModal = (props: any) => {
    const title = props.mode === 'create' ? 'Add' : 'Edit';
    return (
        <Modal show={props.modal} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title} Grad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={props.saveGrad}>
                    {props.errorMessage &&
                        <Form.Group className="mb-3">
                            <Alert variant="warning">
                                {props.errorMessage}
                            </Alert>
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Name"
                            value={props.formData.name}
                            name="name"
                            onChange={props.handleChange}
                            autoFocus
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Please enter email"
                            value={props.formData.email}
                            name="email"
                            onChange={props.handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {title} Grad
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}