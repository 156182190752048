import { Navigate } from "react-router-dom";
import { getAuthentication } from "./service/getAuthentication";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";


function handleStatus(status : number)  {

  switch(status) {
      case 403:
          return <Navigate to="/pending"/>
      case 401:
          return <Navigate to="/login"/>
    default:      
      return <h1>Error</h1>
  }
}

const PrivateRoute = ({children }:{children:any}) : JSX.Element => {

  const [authStatus, setAuthStatus] = useState(-1)
  useEffect(() => {
    const fetchData = async () => {
      const auth = await getAuthentication(); // Wait for resolution
      setAuthStatus(auth);

    };
    fetchData();
  }, []);
  return(<>
    {
    authStatus === -1? <Spinner/>: authStatus === 200? children : handleStatus(authStatus)}
  </>)
  };
  export default PrivateRoute