import {ProgressBar} from "react-bootstrap";
import {Grad} from "../../model/Grad";

export  const MatchUpProgress = ({positions}:any) => {

    // array of grads assigned to list of positions
    const posGradAssignment = positions.map((position: any) => {
        return position.matchUps.map((matchUp: any) => {
            // Handle null grad gracefully based on your preference
            return matchUp.grad || null; // Or replace with a placeholder object if desired
        })
    }).flat()

    const totalMatchUps = posGradAssignment.length
    const totalAssignments = posGradAssignment.filter((grad: Grad) => grad !==null).length

    const percentage = Math.round((totalAssignments/totalMatchUps)*100)


    const render = {
        value:percentage,
        variant: percentage < 100 ? "warning" : "success",
        animated: percentage < 100
    }

   return (
           <ProgressBar
               now={render.value}
               label={`${render.value}% Assigned`}
               variant={render.variant}
               animated ={render.animated}
           />
   )
}

export default MatchUpProgress;
