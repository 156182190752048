import {Grad} from "../model/Grad";
import ApiClient from "../utils/ApiClient";


const BASE_URL = "/api/";
const apiClient = new ApiClient();


async function getSelectableGrads(cohortId: number): Promise<Grad[]> {
    const url = `${BASE_URL}/selectgrads/${cohortId}`;
    const response = await apiClient.get<Grad[]>(url);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}


export {
    getSelectableGrads
}