
import React, { useEffect, useState } from "react";
import {Button, Card, Stack, Table} from "react-bootstrap";
import jwtFetch from "../../utils/JwtFetch";

import User from "../../model/User";


async function getUserRequests(){
    try {
        const res = await jwtFetch("api/users", {
        });
        if (!res.ok) {
            if(res.status === 403){
                window.location.href = "/home"
            }
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        return data;
    } catch (error) {
        window.location.href = "/home"
        console.error("Error fetching users:", error);
        return null;
    }
}
async function changeUserStatus(id : number, status : string, update : boolean, shouldUpdate : any){
    try {
        const res = await jwtFetch(`/api/users/${id}/change-status`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "PUT",
            body: JSON.stringify({
                status:status,
            }),
        })
        if (!res.ok) {
            if(res.status === 403){
                window.location.href = "/home"
            }
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        shouldUpdate(!update);
        return data;
    } catch (error) {
        window.location.href = "/home"
        console.error("Error fetching users:", error);
        return null;
    }
}
async function deleteUser(id: number, status: string, update: boolean, shouldUpdate: any){
    try {
        const res = await jwtFetch(`/api/users/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "DELETE",
            //TODO: edit something here to make it delete user records
            body: JSON.stringify({
                status:status,
            }),
        })
        if (!res.ok) {
            if(res.status === 403){
                window.location.href = "/home"
            }
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        shouldUpdate(!update);
        return data;
    } catch (error) {
        window.location.href = "/admin"
        console.error("Error deleting user:", error);
        return null;
    }
}
function getColorStyle(status : string) : string{

    switch(status){
        case "PENDING":
            return "#F7B538"
        case "APPROVED":
            return "#96E6B3"
        case "REJECTED":
            return "#DB2955"
        default:
            return "black"
    }
}

function AdminPanel(){ 

    const [accounts, setAccounts] = useState<User[] | null>(null)
    const [update, shouldUpdate] = useState<boolean>(false);

    useEffect(() => {
        getUserRequests().then(data => {
                setAccounts(data)
        });
    }, [update])

    return (<>
        {accounts? accounts.map(account => <>
            {account.accountStatus.match("PENDING")?
                <>
                <Card style={{margin:"30px 0 0 0", padding:"10px"}}>
                    <Stack direction="horizontal" gap={3}>
                        <Stack>
                            <Card.Title>{account.email}</Card.Title>
                            <Card.Subtitle>Requesting account verification</Card.Subtitle>
                        </Stack>
                        <span>STATUS:  <b style={{backgroundColor:getColorStyle(account.accountStatus), padding: "3px"}}>{account.accountStatus}</b> </span>
                        <Button variant="outline-success" onClick={() => changeUserStatus(account.id, "APPROVED", update, shouldUpdate)}>Approve</Button>
                        <Button variant="outline-danger" onClick={() => changeUserStatus(account.id, "REJECTED", update, shouldUpdate)}>Reject</Button>
                    </Stack>
                </Card>
                </> : <></>}
        </>) : <h2>No requests</h2>}
        <h1>Users</h1>
        <div className="scrollable-container" style={{margin: "0 0 50px 0"}}>
            <Table striped hover>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Status</th>
                    <th style={{ width: "20%" }}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {accounts?.length === 0 ? (
                    <tr>
                        <td colSpan={3}><h4>No records found</h4></td>
                    </tr>
                ) : (
                    accounts?.map((user: any, index: any) => (
                        user.accountStatus.match("PENDING")?
                            (<></>) : (
                                <tr key={user.id}>
                                    <td><span>{user.email}</span></td>
                                    <td><b style={{backgroundColor:getColorStyle(user.accountStatus), padding: "3px"}}>{user.accountStatus}</b></td>
                                    <td>
                                        {user.accountStatus.match("PENDING")?
                                            <Stack direction="horizontal" gap={2}>
                                                <Button variant="outline-success" size="sm" onClick={() => changeUserStatus(user.id, "APPROVED", update, shouldUpdate)}>Approve</Button>
                                                <Button variant="outline-danger" size="sm" onClick={() => changeUserStatus(user.id, "REJECTED", update, shouldUpdate)}>Reject</Button>
                                            </Stack> : <></>}
                                        {user.accountStatus.match("APPROVED")?
                                            <Button variant="outline-danger" size="sm" onClick={() => changeUserStatus(user.id, "REJECTED", update, shouldUpdate)}>Revoke</Button>
                                            : <></>}
                                        {user.accountStatus.match("REJECTED")?
                                            <Stack direction="horizontal" gap={2}>
                                                <Button variant="outline-success" size="sm" onClick={() => changeUserStatus(user.id, "APPROVED", update, shouldUpdate)}>Approve</Button>
                                                <Button variant="outline-danger" size="sm" onClick={() => deleteUser(user.id, "DELETED", update, shouldUpdate)}>Delete</Button>
                                            </Stack>
                                            : <></>}
                                    </td>
                                </tr>
                            )
                    ))
                )}
                </tbody>
            </Table>
        </div>
        {/*<UserTable/>*/}
    </>);
}
export default AdminPanel;