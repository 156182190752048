import { Button } from "react-bootstrap";
import React from "react";
import {useParams} from "react-router-dom";
import jwtFetch from "../utils/JwtFetch";
import clipBoardCSV from "../utils/ClipBoardCSV";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export const MatchUpExport = () => {
    const {id} = useParams()
    const exportMatchUps = async () => {
        try {
            const response = await jwtFetch(`/api/matchups/export/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Get the filename from the Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                console.log(contentDisposition)
                let filename = "export.csv";
                if (contentDisposition) {
                    filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
                }

                // Create a Blob from the response and create a download link
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename || 'downloaded_file';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error('File download failed:', response.statusText);
                toast.error("positions missing assignment")
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }
    const copyCsv = async () => {
        try {
            const response = await jwtFetch(`/api/matchups/export/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                await clipBoardCSV(response);
                toast.success("CSV copied to clipboard")
            } else {
                console.error('File download failed:', response.statusText);
                toast.error("positions missing assignment")
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    return (
        <div className="d-flex flex-row bd-highlight" >
            <ToastContainer autoClose={1500} stacked></ToastContainer>
            <Button className="btn btn-primary mx-3 mt-3" onClick={exportMatchUps}>
                <i className="bi bi-filetype-csv"></i> Export Match Ups
            </Button>
            <Button className="btn btn-primary mt-3" onClick={copyCsv}>
                <i className="bi bi-filetype-csv"></i> Copy CSV
            </Button>
        </div>
    )
}
export default MatchUpExport;
