// noinspection SpellCheckingInspection,DuplicatedCode

import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Lead} from "../../model/Lead";
import {TeamLeadsTable} from "./TeamLeadsTable"
import {Alert, Button, Form, Modal} from "react-bootstrap";
import jwtFetch from "../../utils/JwtFetch";
import clipBoardCSV from "../../utils/ClipBoardCSV";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TeamLeadsSetup = () => {
    const { id } = useParams();
    const [reloadData, setReloadData] = useState(true);
    const [leadList, setLeadlist] = useState<Lead[]>([]);
    const [selectedLeadId, setSelectedLeadId] = useState<number>();
    const [errorMessage, setErrorMessage] = useState('');
    const [modal, setModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const openModal = () => {
        setModal(true);
    }
    const closeModal = () => {
        setModal(false);
        initializeData();
    }

    function initializeData() {
        setErrorMessage('');
        setName('');
        setEmail('');
        setSelectedLeadId(0);
    }

    useEffect(() => {
        const fetchData = async () => {
            const url: string = `/api/teamLeads/${id}`;
            const response = await jwtFetch(url);
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }
            const responseData = await response.json();
            const loadedData: Lead[] = [];
            for (const key in responseData) {
                const model = new Lead(
                    responseData[key].id,
                    responseData[key].cohortId,
                    responseData[key].name,
                    responseData[key].email
                );
                loadedData.push(model);
            }
            setLeadlist(loadedData);
        }
        fetchData().catch((error: any) => {
            // console.log(error)
            toast.error(error)
        })

        if (reloadData) {
            setReloadData(false);
        }
        // eslint-disable-next-line
    }, [reloadData]);

    function handleNameChange(e: any) {
        setName(e.target.value);
    }

    function handleEmailChange(e: any) {
        setEmail(e.target.value);
    }

    const saveLead = async (e: any) => {
        e.preventDefault();

        const url = `/api/teamLeads/save/${id}`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: selectedLeadId,
                cohortId: id,
                name: name,
                email: email
            })
        };

        const returnResponse = await jwtFetch(url, requestOptions);

        if (!returnResponse.ok) {
            const data = await returnResponse.json();
            setErrorMessage(data.message);
        } else {
            setReloadData(true)
            closeModal();
        }
    }

    const deleteLead = async () => {
        const url = `/api/teamLeads/remove/${selectedLeadId}`;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const returnResponse = await jwtFetch(url, requestOptions);
        if (!returnResponse.ok) {
            const data = await returnResponse.json();
            setErrorMessage(data.message);
        } else {
            closeModal();
            setReloadData(true);
        }
    }

    const importLeads = async () => {
        if (selectedFile != null) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            if (id) {
                formData.append('cohortId', id);
            }
            try {
                const response = await jwtFetch(`/api/teamLeadsCSV/${id}`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    toast.success('File uploaded successfully')
                    // console.log('File uploaded successfully');
                    setReloadData(true);
                } else {
                    const data = await response.json();
                    setErrorMessage(data.message);
                    // alert(data.message)
                    toast.error(<div> {response.statusText} <br></br> {data.message}</div>)
                 }
            } catch (error) {
                setErrorMessage(error + '');
                console.error('Error uploading file:', error);



            }
        } else {
            toast.error("Please select file to upload")
            // alert("Please select file to upload")
        }
        initializeData();
    }
    const exportLeads = async () => {

        try {
            const response = await jwtFetch(`/api/teamLeadsCSV/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Get the filename from the Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = "export.csv";
                if (contentDisposition) {
                    filename = contentDisposition.split('filename=')[1].replace(/"/g, '');
                    console.log(filename)
                }

                // Create a Blob from the response and create a download link
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                console.log(a)
                a.href = url;
                a.download = filename || 'downloaded_file';

                document.body.appendChild(a);
                console.log(a)
                a.click();
                document.body.removeChild(a);

            } else {
                console.error('File download failed:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        initializeData();
    }
    const copyLeadstoClipBoard = async () => {

        try {
            const response = await jwtFetch(`/api/teamLeadsCSV/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                await clipBoardCSV(response);
                toast.success("CSV copied to clipboard")
            } else {
                toast.error('File copy failed:' + response.statusText)
                // console.error('File copy failed:', response.statusText);
            }
        } catch (error) {
            toast.error('Error copying file:'+ error)
            // console.error('Error copying file:', error);
        }
        initializeData();
    }

    const handleFileChange = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    return (
        <>
            <ToastContainer stacked />
            <TeamLeadsTable
                copyLeadstoClipBoard={copyLeadstoClipBoard}
                leadList={leadList}
                deleteLead={deleteLead}
                setSelectedLeadId={setSelectedLeadId}
                exportLeads={exportLeads}
                importLeads={importLeads}
                handleFileChange={handleFileChange}
                openModal={openModal}
                setName={setName}
                setEmail={setEmail}
            />

            <Modal show={modal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Team Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={saveLead}>
                        {errorMessage &&
                            <Form.Group className="mb-3">
                                <Alert variant="warning">
                                    {errorMessage}
                                </Alert>
                            </Form.Group>
                        }
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={handleNameChange}
                                autoFocus
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Please enter email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save Lead
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}