import {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import { Link, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//handles the login feature
async function handleLogin(e : any, setIsLoggedIn : any, setShowLogout : any){

    let loginSuccessful = false;

    e.preventDefault()

    if(sessionStorage.getItem('X-Auth-Token') != null){
        sessionStorage.setItem('X-Auth-Token', '');
    }

    const data = new FormData(e.target);

    //format form data into object
    const loginData = {
        email : data.get("email"),
        password : data.get("password"),
    }

    //POST request to database at api/login
    await fetch (`/api/login`, {
        
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(loginData),
    }).then(response => {
        handleStatus(response.status);
        if(response.ok){
            const token = response.headers.get('X-Auth-Token')!;
            sessionStorage.setItem('X-Auth-Token', token)

            const admin = response.headers.get('Admin')!;
            if(admin !== null){
                sessionStorage.setItem('Admin', admin)
            }

            loginSuccessful = true;
            setShowLogout(true);
        }
    })
  
    .catch(error => {
        console.log(error);
    });
    setIsLoggedIn(loginSuccessful)
}

function handleStatus(status : number){
    let message = '';
    switch(status){
        
        case 404:
            message = "Email not found"
            break;
        case 401:
            message = "Incorrect password"
            break;
        case 500:
            message = "Internal server error!"
            break;
        default:
            message = "Error "  + status;
            break;
    }
    toast.error(message);
}

function Login(props : any) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    return (
        <>
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
            <h1>Log In</h1>
            <Form id={"formLogin"} onSubmit={event => handleLogin(event, setIsLoggedIn, props.setShowLogout)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Email" name="email"/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" name="password"/>
                </Form.Group>
                <Button variant='primary' type="submit" >Login</Button><br/>
                <Link to="/Register">No account? Register here!</Link>
            </Form>
            {isLoggedIn? <Navigate to="/home"/>: ""}
            
        </>
    );
}

export default Login;
