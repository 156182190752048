
import { Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function isYorkEmail(email : string){
    const domain = "yorksolutions.net";
    return email.endsWith(`@${domain}`)
}

//handles Registration
async function handleRegistration(e : any, setIsSubmitted : any){
    

    let registrationSuccessful = false;

    e.preventDefault()

    const data = new FormData(e.target);

    if(!data.get("email")){
        toast.error("Please provide an email");
        return;
    }
    if(!data.get("password")){
        toast.error("Please provide a password");
        return;
    }


    const passwordConfirm = data.get("passwordConfirm")!.toString();

    //format form data into object
    const registrationData = {
        email : data.get("email")!.toString(),
        password : data.get("password")!.toString()
    }

    //require york email for registration
    if(!isYorkEmail(registrationData.email!.toString())){
        toast.error("You must register with a York email!")
        return;
    }
    if(registrationData.password!.toString().length < 10){
        toast.error("Password must be at least 10 characters!")
        return;
    }
    if(!(registrationData.password === passwordConfirm)){
        toast.error("Passwords don't match!")
        return;
    }
    //POST request to database at api/login
    await fetch (`api/register`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(registrationData),
    }).then(response => {
        if (!response.ok) {

            //409 email already exists
            if(response.status === 409){
                toast.error("Email already in use!")
            }
        }
        else{
            //201 OK (Created)
            registrationSuccessful = true;
        }
        

    }).catch(error => {
        console.log(error);
    });
    setIsSubmitted(registrationSuccessful)
}


function Register() {

    const [isSubmitted, setIsSubmitted] = useState(false);

    return (
        <>
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
         <h1>Register</h1>

         <Form onSubmit={event => handleRegistration(event, setIsSubmitted)} id={"formRegister"}>
            <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name = {"email"}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password"  name = "password"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Password"  name = "passwordConfirm"/>
            </Form.Group>
            <Button variant="primary" type="submit">Register</Button> <br/>
            <Link to = "/login">Back to Login</Link>
             {isSubmitted && <Navigate to="/register-success" />}
        </Form>
        </>
    );
}

export default Register;