import { Button, Modal } from "react-bootstrap";
import {Position} from "../../service/positions";
import {MatchUp} from "../../service/matchUps";
import AltMatchUpCard from "../MatchUpCard/AltMatchUpCard";

const MatchUpModal = (props: any) => {
    const selectedPosition: Position | null = props.selectedPosition
        if (selectedPosition !== null) {
            return (
                <Modal show={props.showMatchUpModal} onHide={props.closeMatchUpModal} size="xl">
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                position Id: {selectedPosition?.id} with {selectedPosition.teamLead.name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPosition.matchUps.sort((a:MatchUp,b:MatchUp) => a.id-b.id).map((matchUp:MatchUp, i:number) => (
                                <AltMatchUpCard
                                    selectedPosition ={selectedPosition}
                                    key={matchUp.id}
                                    idx={i}
                                    matchUp={matchUp}
                                    grads={props.gradList}
                                    handleSelect={props.handleSelect}
                                    handleRadio={props.handleRadio}
                                />
                            ))}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={props.closeMatchUpModal} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                </Modal>
            )
        } else {
            return(
                <Modal show={props.showMatchUpModal} onHide={props.closeMatchUpModal}>
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={props.closeMatchUpModal} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                </Modal>
            )
        }
}

export default MatchUpModal;