import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Cohort } from "../../model/Cohort";
import CohortCard from "./CohortCard";
import ApiClient, { ApiResponse } from "../../utils/ApiClient";
import { AddCohortModal } from "./AddCohortModal";


export const Cohorts = () => {
    const [addCohortModal, setAddCohortModal] = useState(false);
    const [reloadData, setReloadData] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [cohorts, setCohorts] = useState<Cohort[]>([]);
    const [cohortName, setCohortName] = useState('');
    const [cohortId, setCohortId] = useState(0);
    const [description, setDescription] = useState('');

    const apiClient = useMemo(() => new ApiClient(), []);

    useEffect(() => {
        const fetchData = async () => {
            const response: ApiResponse<Cohort[]> = await apiClient.get<Cohort[]>(`/api/home/cohorts`);
            if (response.success && response.data) {
                setCohorts([...response.data]);
            } else {
                setErrorMessage(response.error ? response.error : 'Unknown error occurred!')
            }
        };
        if (reloadData) {
            setReloadData(false);
        }
        fetchData();
    }, [reloadData, apiClient]);


    const saveCohort = async (e: any) => {
        e.preventDefault();
        const url = `/api/home/cohorts/save`;
        const response = await apiClient.post<Cohort>(url, { id: cohortId, name: cohortName, description: description });
        if (response.error) {
            setErrorMessage(response.error);
        } else {
            setAddCohortModal(false);
            setReloadData(true);
            initializeData();
        }
    }

    const deleteCohort = async (id: number) => {
        const url = `/api/home/cohorts/id/${id}`;
        const response = await apiClient.del<any>(url);
        if (response.success) {
            setReloadData(true);
            initializeData();
        } else {
            setErrorMessage(response.error ? response.error : "Unknown error occured");
        }
    }

    function editCohort(cohort: Cohort) {
        setCohortId(cohort.id);
        setCohortName(cohort.name);
        setDescription(cohort.description);
        openModal();
    }

    const openModal = () => {
        setAddCohortModal(true);
    }
    const closeModal = () => {
        setAddCohortModal(false);
        initializeData();
    }

    function initializeData() {
        setErrorMessage('');
        setCohortName('');
        setCohortId(0);
        setDescription('');
    }

    return (
        <div>
            <h1>Cohort Management</h1>
            <div className="d-flex flex-row-reverse bd-highlight">
                <div className="p-2 bd-highlight">
                    <Button variant="primary" onClick={openModal}>
                        + Add Cohort
                    </Button>
                </div>
            </div>

            {cohorts.length === 0 ? (
                <div className="card">
                    <div className="card-body">
                        <span>No records found</span>
                    </div>
                </div>
            ) : (
                <>
                    {cohorts.map(cohort =>
                        <CohortCard key={cohort.id} cohort={cohort} deleteCohort={deleteCohort} editCohort={editCohort} />
                    )}
                </>
            )}

            <AddCohortModal
                addCohortModal={addCohortModal}
                closeModal={closeModal}
                saveCohort={saveCohort}
                errorMessage={errorMessage}
                cohortName={cohortName}
                setCohortName={setCohortName}
                description={description}
                setDescription={setDescription}
            />

        </div>
    );
}