import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AltPositionCard from "../PositionCard/AltPositionCard";
import * as teamLeadService from '../../service/teamLeads';
import * as positionService from '../../service/positions';
import * as selectGradService from '../../service/selectGrads';
import * as matchUpService from '../../service/matchUps';
import {TeamLead} from "../../service/teamLeads";
import {Position} from "../../service/positions";
import { Grad } from "../../model/Grad";
import {Link, useParams} from "react-router-dom";
import MatchUpExport from "../MatchUpExport";
import {CohortNamePageHeader} from "../PageHeader/CohortNamePageHeader";
import MatchUpModal from "./MatchupModal";
import MatchUpProgress from "./MatchUpProgress";
import {ToastContainer,toast} from "react-toastify";


function AltTeamLeads() {

    const params = useParams();
    const cohortId = Number(params.id);

    const [teamLeads, setTeamLeads] = useState<TeamLead[]>([]);
    const [positions, setPositions] = useState<Position[]>([]);

    const[showMatchUpModal, setShowMatchUpModal] = useState<boolean>(false);
    const[selectedPosition, setSelectedPosition] = useState<Position | null>(null)

    const[selectGradList, setSelectGradList] = useState<Grad[]>([])

    const closeMatchUpModal = ():void => {
        setShowMatchUpModal(false);
        setSelectedPosition(null);
    };
    const openMatchUpModal = (position: Position | null):void => {
        setShowMatchUpModal(true);
        setSelectedPosition(position);
    };


    useEffect(() => {
        positionService.getAllCohortPositions(cohortId)
            .then(positions => setPositions([...positions]))
            .catch(error => toast.error(error));
        teamLeadService.getAllCohortTeamLeads(cohortId)
            .then(teamLeads => setTeamLeads([...teamLeads]))
            .catch(error => toast.error(error));
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        selectGradService.getSelectableGrads(cohortId)
            .then(grads => setSelectGradList([...grads]))
            .catch(error => toast.error(error))
        // eslint-disable-next-line
    }, [positions]);

    const handleDeleteTeamLead =  async (teamLeadId?: number) => {
        try {
            const deleted = await teamLeadService.deleteTeamLead(teamLeadId);
            if (deleted) {
                setTeamLeads([...teamLeads.filter((teamLead) => teamLead.id !== teamLeadId)]);
            }
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleAddPosition = async (teamLeadId: number) => {
        try {
            const newPosition = await positionService.addPosition(teamLeadId);
            setPositions([...positions, newPosition]);
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleDeletePosition = async (positionId: number) => {
        try {
            const deleted = await positionService.deletePosition(positionId);
            if(deleted) {
                setPositions([...positions.filter((position) => position.id !== positionId)]);
            }
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleChangeSelect = async (gradId:number, matchUpId:number) => {
        try {
            const updatedMatchUp = await matchUpService.updateGrad(matchUpId, gradId);
            setPositions([...positions.map(position => {
                if(position.id === updatedMatchUp.position?.id) {
                    position.matchUps = [...position.matchUps.map(matchUp => matchUp.id === updatedMatchUp.id ? updatedMatchUp : matchUp)];
                }
                return position;
            })]);
        }
        catch(error: any) {
            toast.error(error);
        }
    };

    const handleCLickPosition = (position: Position | null) => {
        setSelectedPosition(position)
        openMatchUpModal(position)
    }

    return (
        <div>
            <ToastContainer stacked></ToastContainer>
            <CohortNamePageHeader></CohortNamePageHeader>
            <div className="mt-5">
                <h1 className="display-4">Positions & Match Ups</h1>
            </div>

            <Link  to={`/setup/${cohortId}`} className="btn btn-primary mt-3 mb-2" >
                <i className="bi bi-arrow-left"></i> Manage Cohort
            </Link>

            {teamLeads.map((teamLead) => (
                <Card key={teamLead.id} id={"teamLeadCard"} >
                    <Card.Body>
                        <Card.Title>
                            <span>Team Lead: {teamLead.name} &nbsp; &nbsp; &nbsp; &nbsp; email: {teamLead.email}</span>
                            <Button variant="danger" size="sm" onClick={() => handleDeleteTeamLead(teamLead.id)}>
                                X
                            </Button>
                        </Card.Title>
                        <Card.Text>
                            No. of Positions: <strong>{positions.filter(position => position.teamLead.id === teamLead.id).length}</strong>
                        </Card.Text>
                        <Button variant="primary" size="sm" onClick={() => handleAddPosition(teamLead.id)}>
                            <b>+</b> Add Position
                        </Button>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {positions.filter(position => position.teamLead.id === teamLead.id).map((position) =>
                                <div key={position.id} >
                                    <AltPositionCard
                                        key={position.id}
                                        position={position}
                                        grads={selectGradList}
                                        deletePosition={handleDeletePosition}
                                        handleCLickPosition={handleCLickPosition}
                                    />
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </Card>
            ))}

            <MatchUpProgress positions={positions}></MatchUpProgress>
            <MatchUpExport/>
            <Link to={`/solutions/${cohortId}`} className="btn btn-primary mx-3 mt-3 mb-5">
                Solutions <i className="bi bi-arrow-right"></i>
            </Link>
            <MatchUpModal
                selectedPosition={selectedPosition}
                gradList={selectGradList}
                showMatchUpModal={showMatchUpModal}
                closeMatchUpModal={closeMatchUpModal}
                handleSelect={handleChangeSelect}
            />


        </div>

    )
}

export default AltTeamLeads;