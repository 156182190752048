import SolutionsTable from "../../components/SolutionsTable/SolutionsTable";
import React, {useEffect, useState} from "react";
import {getAllCohortMatchUps} from "../../service/matchUps";
import {MatchUp} from "../../service/matchUps";
import {findSolutions} from "../../service/solutionFinder";
import {Link, useParams} from "react-router-dom";
import {CohortNamePageHeader} from "../../components/PageHeader/CohortNamePageHeader";
function Solutions(){
    const params = useParams();
    const id = Number(params.id);
    useEffect(() => {
        if(id)
            getAllCohortMatchUps(id).then(
                matchUps => {
                    const solutions = findSolutions(matchUps);
                    console.log("solutions", solutions);
                    setSolutions(solutions);
                }
            )
        }
    , [id]);
    const [solutions, setSolutions] = useState<MatchUp[][]>([]);
    return(
        <>
            <CohortNamePageHeader></CohortNamePageHeader>
            <h1>Solutions</h1>
            <Link to={`/matchUps/${id}`} className="btn btn-primary mt-3 mb-5">
                <i className="bi bi-arrow-left"></i> Manage Match Ups
            </Link>
            {solutions[0] === undefined || solutions[0].length === 0 ?
                <h2>
                    No solutions can be generated from these match ups.<br></br>
                    Please check that all match ups have been assigned appropriately.
                </h2>
                :<>
                    <SolutionsTable solutions={solutions}/>
                </>
            }

        </>
    );
}

export default Solutions;