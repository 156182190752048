export interface FetchOptions {
    method?: string;
    headers?: Record<string, string>;
    body?: any; // Adjust type based on expected request bodies
}

async function jwtFetch(url: string, options: FetchOptions = {}): Promise<Response> {
    // Set default options
    options.method = options.method ?? "GET";
    options.headers = options.headers ?? {};

    // Add authentication header with token from local storage
    options.headers["Authorization"] = `Bearer ${sessionStorage.getItem("X-Auth-Token")}`;
    return await fetch (url, options);

    // try {
    //     const response = await fetch (url, options);
    //
    //     // Handle successful responses
    //     if (response.ok) {
    //         return response;
    //     } else {
    //         // Handle errors based on response status code
    //         throw new Error(`HTTP error ${response.status}`);
    //     }
    // } catch (error) {
    //     // Handle network errors or other fetch errors
    //     console.log(error)
    //     throw error;
    // }
}

export default jwtFetch
