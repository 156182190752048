import {Grad} from "../model/Grad";
import {Position} from "./positions";
import jwtFetch from "../utils/JwtFetch";
import ApiClient from "../utils/ApiClient";

const BASE_URL = "/api/matchups";
const apiClient = new ApiClient();

export interface MatchUp {
    id: number;
    grad?: Grad,
    position?: Position,
    isLeadPreferred: boolean
}


async function getAllCohortMatchUps(cohortId: number): Promise<MatchUp[]> {
    const response =  await jwtFetch(
        `${BASE_URL}/${cohortId}`);
    return await response.json();
}

async function updateGrad(matchUpId:number, gradId:number): Promise<MatchUp> {
    const url = `${BASE_URL}/update/${matchUpId}/grad/${gradId}`;
    const response = await apiClient.put<MatchUp>(url, null);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}

async function updatePreferred(matchUpId:number): Promise<MatchUp[]> {
    const url = `${BASE_URL}/update/${matchUpId}`;
    const response = await apiClient.put<MatchUp[]>(url, null);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}


export {
    getAllCohortMatchUps,
    updateGrad,
    updatePreferred
}