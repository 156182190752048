import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Home from "./pages/Home/Home";
import Header from './components/HeaderAndFooter/Header';
import { Setup } from './pages/SetUp/Setup';
import Solutions from "./pages/Solutions/Solutions";
import TeamLeads from "./components/TeamLeads";
import AdminPanel from './pages/Admin/AdminPanel';
import PrivateRoute from './PrivateRoute';
import { Footer } from './components/HeaderAndFooter/Footer';
import PendingPage from './pages/Redirect/Pending';
import RegisterSuccess from './pages/Redirect/RegisterSuccess';
import {SetupMatchUps} from "./pages/SetUpMatchUps/SetupMatchUps";
import { useEffect, useState } from 'react';
import './css/custom.css';
import './App.css';



function App() {
  const [showLogout, setShowLogout] = useState(true);

  useEffect(() => {
    setShowLogout(sessionStorage.getItem('X-Auth-Token') !== null)
  },[])

  return (
    <>
    <Header showLogout={showLogout}/>
      <div className="container">
        <BrowserRouter>
          <Routes>
            
            <Route path="/" element={<Login />}></Route>
            <Route path="/login" element={<Login setShowLogout = {setShowLogout}/>}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/pending" element={<PendingPage />}></Route>
            <Route path="/register-success" element={<RegisterSuccess/>}></Route>
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>}></Route>
            <Route path="/admin" element={<PrivateRoute><AdminPanel /></PrivateRoute>}></Route>
            <Route path="/solutions/:id" element={<PrivateRoute><Solutions /></PrivateRoute>}></Route>
            <Route path="/setup/:id" element={<PrivateRoute><Setup /></PrivateRoute>}></Route>
            <Route path="/matchUps/:id" element={<PrivateRoute><TeamLeads /></PrivateRoute>}></Route>
            <Route path="/test/:id" element={<PrivateRoute><SetupMatchUps /></PrivateRoute>}></Route>
            <Route path={"*"} element={<div> no match </div>}></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}


export default App;
