import { MatchUp } from "./matchUps";
import { TeamLead } from "./teamLeads";
import ApiClient from "../utils/ApiClient";


const BASE_URL = "/api/positions";
const apiClient = new ApiClient();

export interface Position {
    id: number;
    teamLead: TeamLead;
    matchUps: MatchUp[]
}


async function getAllCohortPositions(cohortId: number): Promise<Position[]> {
    const url = `${BASE_URL}/getAll/${cohortId}`;
    const response = await apiClient.get<Position[]>(url);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}

async function addPosition(teamLeadId: number): Promise<Position> {
    const url = `${BASE_URL}/add/${teamLeadId}`;
    const response = await apiClient.post<Position>(url, null);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}

async function deletePosition(positionId: number): Promise<Boolean> {
    const url = `${BASE_URL}/delete/${positionId}`;
    const response = await apiClient.del(url);

    if (response.success) {
        return response.success;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}

export {
    getAllCohortPositions,
    addPosition,
    deletePosition
}