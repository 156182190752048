import { Alert, Button, Form, Modal } from "react-bootstrap";

export const AddCohortModal = (props: any) => {
    return (
        <Modal show={props.addCohortModal} onHide={props.closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={props.saveCohort}>
                    {props.errorMessage &&
                        <Form.Group className="mb-3">
                            <Alert variant="warning">
                                {props.errorMessage}
                            </Alert>
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter cohort name e.g. mm_yyyy Grads"
                            value={props.cohortName}
                            onChange={(e) => { props.setCohortName(e.target.value) }}
                            autoFocus
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter description"
                            value={props.description}
                            onChange={(e) => { props.setDescription(e.target.value) }}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}