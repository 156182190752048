import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import LogoutButton from '../../utils/LogoutButton';


function Header(props : any) {

  // const [showButton, setShowButton] = useState(false)


  // useEffect(() => {
  //   setShowButton(sessionStorage.getItem('X-Auth-Token') !== null);
  // },[window.location])
  


  return (
    <>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            {sessionStorage.getItem("Admin") !== null && 
              <Nav className="me-auto">
                <Nav.Link  href="/admin">Admin Panel</Nav.Link>
              </Nav>
            }
          </Nav>
        </Navbar.Collapse>
        {props.showLogout &&  <LogoutButton/>}
      </Container>
    </Navbar>
    </>
  );
}

export default Header;