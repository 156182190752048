import { Button, Stack, Table } from "react-bootstrap";

import React from "react";

export const TeamLeadsTable = (props: any) => {
    function editTeamLead(lead: any) {
        props.setSelectedLeadId(lead.id);
        props.setEmail(lead.email)
        props.setName(lead.name)
        props.openModal();
    }

    return (
        <>
            <h1>Team Leads</h1>
            <div className="d-flex flex-row-reverse bd-highlight">
                <div className="p-2 bd-highlight">
                    <Button onClick={props.copyLeadstoClipBoard}>
                        <i className="bi bi-filetype-csv"></i> Copy Csv
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="secondary" onClick={props.exportLeads}>
                        <i className="bi bi-filetype-csv"></i> Export
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="secondary" onClick={props.importLeads}>
                        <i className="bi bi-filetype-csv"></i> Import
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <input
                        type="file"
                        className="form-control"
                        onChange={props.handleFileChange}
                    />
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="primary" onClick={props.openModal}> <b>+</b> Add New</Button>
                </div>
            </div>
            <div className="scrollable-container">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: "20%" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.leadList.length === 0 ? (
                            <tr>
                                <td colSpan={4}><span>No records found</span></td>
                            </tr>
                        ) : (
                            props.leadList.map((lead: any, index: any) => (
                                <tr key={lead.id}>
                                    <td>{++index}</td>
                                    <td>{lead.name}</td>
                                    <td>{lead.email}</td>
                                    <td>
                                        <Stack direction="horizontal" gap={2}>
                                            <Button variant="primary" size="sm"
                                                onClick={(event: any) => { editTeamLead(lead) }}
                                            >
                                                Edit
                                            </Button>
                                            <Button variant="danger" size="sm"
                                                onMouseOver={() => { props.setSelectedLeadId(lead.id) }}
                                                onClick={props.deleteLead}>Delete
                                            </Button>{' '}
                                        </Stack>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}