import React from "react";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";


const MatchUpCard = ({matchUp, grads, idx, handleSelect, handleRadio}:any) => {


    return (
        <Row className="mb-3">
            <Form.Label>Grad {idx + 1}</Form.Label>
            <Col>
                <Form.Select
                    name="grad"
                    onChange={(event) => handleSelect(event, matchUp.id)}
                    value={matchUp.grad?.id || 0}
                >
                    <option value={0}></option>
                    {grads.map((grad: any) => (
                        <option key={grad.id} value={grad.id}>
                            {grad.name}
                        </option>
                    ))}
                </Form.Select>
            </Col>
            <Col>
                <Form.Check
                    type="radio"
                    label="Lead Preferred"
                    name={matchUp.position.id?.toString()}
                    checked={matchUp.isLeadPreferred && true}
                    onChange={() => handleRadio(matchUp)}
                />
            </Col>
        </Row>
    )
}

export default MatchUpCard;