import Form from "react-bootstrap/Form";
import {Button} from "react-bootstrap";
import {Grad} from "../../model/Grad";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const AltMatchUpCard = ({matchUp, grads, idx, handleSelect, selectedPosition}: any) => {
    const gradButtons = (grads: Grad[], selectedPosition: any) => {
        const gradsAssignedHere: Grad[] = selectedPosition.matchUps.map((matchUp: { grad: Grad; }) => matchUp.grad);
        const filteredGrads: Grad[] = grads.filter(grad => {
            return !gradsAssignedHere.some(exclusion => {
                // eslint-disable-next-line
                for (const key in exclusion) {
                    if (grad.id === exclusion.id) {
                        return true;
                    }
                }
                return false;
            });
        });

        return(

            filteredGrads.map((grad:Grad) => (
                <div key={grad.id}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{grad.email}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                        <Button
                            ref={ref}
                            {...triggerHandler}
                            className="m-1"
                            onClick={() => handleSelect(grad.id, matchUp.id)}
                            key={grad.id}>
                            {grad.name}
                        </Button>
                        )}
                    </OverlayTrigger>
                </div>
                ))
        )

    }
    return (
            <>
                {(matchUp.grad === null) ?
                    <>
                        <Form.Label>Select grad for interview {idx + 1}</Form.Label>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',marginBottom:'30px' }}>
                            {gradButtons(grads,selectedPosition)}
                        </div>
                    </>
                     :
                    <>
                        <Form.Label>{matchUp.grad?.name} assigned to interview {idx + 1}</Form.Label>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',marginBottom:'30px' }}>
                            <Button className="m-1"
                                onClick={() => handleSelect(0, matchUp.id)}
                                variant="danger">
                                Unassign {matchUp.grad?.name}
                            </Button>
                            {gradButtons(grads,selectedPosition)}
                        </div>
                    </>
                }
            </>

    )
}

export default AltMatchUpCard;