import { Position } from "./positions";
import ApiClient from "../utils/ApiClient";


const BASE_URL = "/api/teamLeads";
const apiClient = new ApiClient();

export interface TeamLead {
    id: number;
    email: string;
    name: string;
    positions?: Position[]
}

export interface SaveTeamLead {
    email: string;
    name: string;
    positions?: Position[]
}

async function getAllCohortTeamLeads(cohortId: number): Promise<TeamLead[]> {
    const url = `${BASE_URL}/${cohortId}`;
    const response = await apiClient.get<TeamLead[]>(url);

    if (response.success && response.data) {
        return response.data;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}

// async function save(teamLead: SaveTeamLead, cohortId: number): Promise<TeamLead> {
//     const response = await jwtFetch(
//         `${BASE_URL}/save/${cohortId}`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(teamLead)
//     });
//     return await response.json();
// }


async function deleteTeamLead(teamLeadId?: number): Promise<Boolean> {
    const url = `${BASE_URL}/remove/${teamLeadId}`;
    const response = await apiClient.del(url);

    if (response.success) {
        return response.success;
    }
    return Promise.reject(response.error ? response.error : 'Error occurred!');
}


export {
    getAllCohortTeamLeads,
    deleteTeamLead
}