export const clipBoardCSV = async (response: Response) => {
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {

        if (event.target && typeof event.target.result === "string") {
            const csvData = event.target.result;
            const rows = csvData?.split('\n');
            const formattedData = rows.map(row => {
                const columns = row.split(','); // Assuming ',' is the delimiter
                return columns.join('\t'); // Replace ',' with '\t' (tab) as new delimiter
            }).join('\n');

            navigator.clipboard.writeText(formattedData)
                .then(() => {
                    // react-toastify?
                    console.log('Copied to clipboard!');
                })
                .catch((err) => {
                    console.error('Failed to copy:', err);
                });
        }
    };
    reader.readAsText(blob);
}

export default clipBoardCSV;