import React from "react";
import { Button } from "react-bootstrap";

function ExportTableButton({ table, buttonText, csvFileName}: {table: string[][], buttonText: string, csvFileName: string}) {
    const handleExportCSV = () => {
        const csvData = tableToCSV(table);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${csvFileName}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <Button onClick={handleExportCSV} variant="primary">
            <i className="bi bi-filetype-csv"></i> {buttonText}
        </Button>
    );
}

function tableToCSV(table: string[][]): string {
    const csvRows = table.map(row => row.join(',')).join('\n');
    return csvRows;
}

export default ExportTableButton;
