import { Link, useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { Grad } from "../../model/Grad";
import '../../components/GradSetup/GradsTable.css';
import { GradsTable } from "../../components/GradSetup/GradsTable";
import { TeamLeadsSetup } from "../../components/TeamLeadSetup/TeamLeadSetup";
import ApiClient, { ApiResponse } from "../../utils/ApiClient";
import { AddGradModal } from "../../components/GradSetup/AddGradModal";
import { CohortNamePageHeader } from "../../components/PageHeader/CohortNamePageHeader";
import { toast } from 'react-toastify';

export const Setup = () => {
    const { id } = useParams();
    const [reloadData, setReloadData] = useState(true);
    const [gradList, setGradlist] = useState<Grad[]>([]);
    const [selectedGradId, setSelectedGradId] = useState<number>();
    const [errorMessage, setErrorMessage] = useState('');
    const [modal, setModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const apiClient = useMemo(() => new ApiClient(), []);

    useEffect(() => {
        const fetchData = async () => {
            const response: ApiResponse<Grad[]> = await apiClient.get(`/api/grads/getAll/${id}`);
            if (response.success && response.data) {
                setGradlist([...response.data]);
            } else {
                setErrorMessage(response.error ? response.error : 'Unknown error occurred!')
            }
        };
        if (reloadData) {
            setReloadData(false);
        }
        fetchData();
    }, [reloadData, apiClient, id]);


    const initialFormData: Grad = {
        id: 0,
        cohortId: Number(id),
        name: '',
        email: ''
    }
    const [formData, setFormData] = useState(initialFormData);
    const [mode, setMode] = useState('');

    const handleMode = (str: string) => {
        setMode(str);
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFormData = (data: Grad) => {
        setFormData(data);
    }

    const saveGrad = async (e: any) => {
        e.preventDefault();
        const url = `/api/grads/save`;
        const response = await apiClient.post<Grad>(url, formData);
        if (response.error) {
            setErrorMessage(response.error);
        } else {
            closeModal();
            setFormData(initialFormData);
            toast.info(mode === 'create' ? 'New Grad added!' : 'Grad updated')
            setReloadData(true);
        }
    };

    const deleteGrad = async (e: any) => {
        const url = `/api/grads/delete/${selectedGradId}`;
        const response = await apiClient.del<any>(url);
        if (response.success) {
            setReloadData(true);
        } else {
            setErrorMessage(response.error ? response.error : "Unknown error occurred");
        }
    }

    const importGrads = async () => {
        if (selectedFile != null) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            if (id) {
                formData.append('cohortId', id);
            }
            const response = await apiClient.post<any>("/api/grads/import", formData);
            if (response.success) {
                console.log('File uploaded successfully');
                toast.success('File uploaded successfully')
                setReloadData(true);
            } else {
                // alert(response.error ? response.error : "Unknow error occured");
                toast.error(response.error ? response.error : "Unknow error occured");
            }
        } else {
            // alert("Please select file to upload");
            toast.error("Please select file to upload");
        }
        initializeData();
    }

    const exportGrads = async () => {
        try {
            const response = await apiClient.getFile<any>(`/api/grads/export/${id}`, { responseType: 'blob' });
            if (response.success) {
                let filename = response.filename;
                const blob = await response.data;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename || 'downloaded_file.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                console.error('File download failed:');
                toast.error('File download failed:');
            }
        } catch (error: any) {
            console.error('Error downloading file:', error);
            toast.error('Error downloading file:', error);
        }
        initializeData();
    }

    const handleFileChange = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const openModal = () => {
        setModal(true);
    }
    const closeModal = () => {
        setModal(false);
        initializeData();
    }

    function initializeData() {
        setErrorMessage('');
        setFormData(initialFormData);
    }

    return (
        <>
            <CohortNamePageHeader></CohortNamePageHeader>
            <GradsTable
                gradList={gradList}
                deleteGrad={deleteGrad}
                setSelectedGradId={setSelectedGradId}
                exportGrads={exportGrads}
                importGrads={importGrads}
                handleFileChange={handleFileChange}
                openModal={openModal}
                handleFormData={handleFormData}
                handleMode={handleMode}
            />
            <TeamLeadsSetup></TeamLeadsSetup>

            <Link to={`/matchUps/${id}`} className="btn btn-primary mt-3 mb-5 float-end" >
                Manage Match Ups <i className="bi bi-arrow-right"></i>
            </Link>

            <AddGradModal
                modal={modal}
                closeModal={closeModal}
                errorMessage={errorMessage}
                saveGrad={saveGrad}
                formData={formData}
                handleChange={handleChange}
                handleMode={handleMode}
                mode={mode}
            />

        </>
    );
}