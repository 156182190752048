import React from 'react';
import { Cohorts } from '../../components/Cohorts/Cohorts';

function Home() {
    return (
        <Cohorts />
    );
}

export default Home;

