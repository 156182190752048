import { Button } from "react-bootstrap"

function logout(){
    sessionStorage.removeItem('X-Auth-Token');
    sessionStorage.removeItem('Admin');
    window.location.href = "/login" 
}

function LogoutButton(){

    return (<>
        <Button variant="outline-danger" onClick={logout}>Log Out</Button> 
    </>)
}
export default LogoutButton