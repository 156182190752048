import {MatchUp} from "./matchUps";

function findSolutions(allMatchUps: MatchUp[]) {
    //group the matchUps into groups with the same position.
    const groupMap = new Map();
    for (const matchUp of allMatchUps) {
        if (groupMap.has(matchUp.position?.id)) {
            groupMap.get(matchUp.position?.id).push(matchUp);
        } else {
            groupMap.set(matchUp.position?.id, [matchUp]);
        }
    }
    //for each group, use a for loop to select one.

    //keep a list of the used grads.
    //if the grad is used, don't select them.
    //if they are unused, select them.
    //if all of the positions are filled, we are done.
    let selectedGrads = new Set([]);
    let selectedMatchUps: MatchUp[] = [];
    let solutions: MatchUp[][] = [];
    backTrack2(groupMap, selectedGrads, solutions, selectedMatchUps, 0);
    return solutions;
}

function backTrack2(groupMap: Map<number, MatchUp[]>, selectedGrads: Set<number>, solutions: MatchUp[][], selectedMatchUps: MatchUp[], index: number) {
    //if all of the positions are filled, we are done.
    if (selectedGrads.size === groupMap.size) {
        solutions.push([...selectedMatchUps]);
        return;
    }
    //for each group, use a for loop to select one.
    let matchUps: MatchUp[] | undefined = groupMap.get(Array.from(groupMap.keys())[index])
    if (matchUps) {
        for (const matchUp of matchUps) {
            if (!selectedGrads.has(matchUp.grad?.id as number)) {
                selectedGrads.add(matchUp.grad?.id as number);
                selectedMatchUps.push(matchUp);
                backTrack2(groupMap, selectedGrads, solutions, selectedMatchUps, index + 1);
                selectedGrads.delete(matchUp.grad?.id as number);
                selectedMatchUps.pop();
            }
        }
    }
}

//takes an array of MatchUps, a solution, and scores each MatchUp in the solution
function scoreSolution(array: Array<MatchUp>) {
    // takes a single array of MatchUps and scores each MatchUp
    let score = 0;
    for(let i = 0; i < array.length; i++) { // for each MatchUp in the array
        let matchA = array[i];
        if(matchA.isLeadPreferred){ // if the MatchUp is preferred, score is increased
            score+=2;
        } else {
            score++;
        }
    }
    return score;
}

export {
    findSolutions,
    scoreSolution
};