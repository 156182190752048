//change prop to have match up array
import React from "react";
import {Table} from "react-bootstrap";
import {MatchUp} from "../../service/matchUps";
import {scoreSolution} from "../../service/solutionFinder";
import AssignmentRow from "./AssignmentRow";
import ExportTableButton from "./ExportTableButton";

function SolutionsTable({solutions}: { solutions: MatchUp[][] }) {
    //sort the match ups in each solution by their position id, to ensure correct order.
    const sortedSolutions = solutions.map((solution: MatchUp[]) => solution.sort((a: MatchUp, b: MatchUp):number => (a.position?.id?? 0) - (b.position?.id?? 0)));
    // sort the solution in solutions by their score.
    const sortedByScore = sortedSolutions.sort((a: MatchUp[], b: MatchUp[]) => scoreSolution(b) - scoreSolution(a));
    //let's just show the top 5 solutions

    //generate all table data.
    const topSortedSolutions = sortedByScore.length > 5 ? sortedByScore.slice(0,5) : sortedByScore;
    const headers = ["", ...topSortedSolutions.map((_, idx) => `Solution ${idx + 1}`)];
    const scores = topSortedSolutions.map((solution: MatchUp[]) => scoreSolution(solution));
    const scoresRow = ["Scores", ...scores.map((score)=> score.toString())];
    const assignmentRows = topSortedSolutions[0].map((MatchUpOfPositionFilled: MatchUp) => {
        const positionInfo = `Pos${MatchUpOfPositionFilled.position?.id} led by ${MatchUpOfPositionFilled.position?.teamLead.name}`;
        const assignmentRow = topSortedSolutions.map((solution: MatchUp[]) => {
            //for each solution, find the matchUp that contains the grad for the current matchUp.
            const matchUp = solution.find((matchUp2: MatchUp) => matchUp2.position?.id === MatchUpOfPositionFilled.position?.id);
            return `${matchUp?.grad?.name} ${matchUp?.isLeadPreferred ? " LP" : " NLP"}`
        });
        return [positionInfo, ...assignmentRow];
    });
    const table = [headers, scoresRow, ...assignmentRows];
    return (
        <>
            <Table striped="columns">
                <caption>displaying {topSortedSolutions.length} of {sortedByScore.length} valid solution(s)</caption>
                <tbody>
                <tr>
                    {headers.map((header, idx)=> idx === 0 ?
                        <td><ExportTableButton table={table} buttonText={"Export All"} csvFileName={"solutions"}/></td>
                        :<td><ExportTableButton table={table.map((row)=> [row[0], row[idx]])} buttonText={`Export`} csvFileName={header}/></td>)}
                </tr>
                <tr>
                    {headers.map((header)=> <th>{header}</th>)}
                </tr>
                <tr>
                    <th>Scores</th>
                    {scores.map((score)=><td>{score}</td>)}
                </tr>
                {assignmentRows.map((assignmentRow) =>{
                        return <tr>
                            {assignmentRow.map((assignment, idx) =>
                                idx === 0 ? <th>{assignment}</th> : <AssignmentRow assignment={assignment}/>
                            )}
                        </tr>;
                    }
                )}
                </tbody>
            </Table>
        </>
    );
}



export default SolutionsTable;