import jwtFetch from "./JwtFetch";

export interface ApiResponse<T> {
    success: boolean;
    data?: T;
    error?: string;
    filename?: string;
}

class ApiClient {
    async request<T>(method: string, url: string, body?: any): Promise<ApiResponse<T>> {
        try {
            const options: RequestInit = {
                method,
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("X-Auth-Token")}`,
                    ...(body instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
                },
                body: body instanceof FormData ? body : (body ? JSON.stringify(body) : undefined),
            };

            const response = await fetch(url, options);
            // Check if the response is empty before parsing as JSON
            const responseData = response.status === 204 ? null : await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }
            return { success: true, data: responseData };
        } catch (error: any) {
            return { success: false, error: error.message || 'Something went wrong' };
        }
    }

    async get<T>(url: string): Promise<ApiResponse<T>> {
        return this.request<T>('GET', url);
    }

    async getFile<T>(url: string, options?: { responseType?: 'json' | 'blob' }): Promise<ApiResponse<T>> {
        try {
            const response = await jwtFetch(url, {
                method: 'GET',
                ...(options?.responseType === 'blob' && { responseType: 'blob' }),
            });

            if (response.ok) {
                const filename = response.headers.get('Content-Disposition')?.split('filename=')[1].replace(/"/g, '') || 'export.csv';
                if (options?.responseType === 'blob') {
                    const blobData = await response.blob();
                    return { success: true, data: blobData as any as T, filename };
                } else {
                    const jsonData = await response.json();
                    return { success: true, data: jsonData as any as T, filename };
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error: any) {
            throw new Error(error.message || 'Something went wrong');
        }
    }

    async post<T>(url: string, body: any): Promise<ApiResponse<T>> {
        return this.request<T>('POST', url, body);
    }

    async put<T>(url: string, body: any): Promise<ApiResponse<T>> {
        return this.request<T>('PUT', url, body);
    }

    async del<T>(url: string): Promise<ApiResponse<T>> {
        return this.request<T>('DELETE', url);
    }
}

export default ApiClient;