import {Link, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {ApiResponse} from "../../utils/ApiClient";
import {Cohort} from "../../model/Cohort";
import ApiClient from "../../utils/ApiClient";


export const CohortNamePageHeader = () => {
    const {id} = useParams()
    const [cohortName,setCohortName] = useState("")
    const [errorMessage,setErrorMessage] = useState("")
    const apiClient = useMemo(() => new ApiClient(), []);
    useEffect(() =>{
        const fetchData = async () => {
            const response: ApiResponse<Cohort> = await apiClient.get(`/api/home/cohorts/${id}`);
            if (response.success && response.data) {
                setCohortName(response.data.name);
            } else {
                setErrorMessage(response.error ? response.error : 'Unknown error occurred!')
            }
        };

        fetchData();
    },[id,apiClient])

    const renderName = () => {
        if (cohortName){
            return(
                <>
                    <h4>Cohort: {cohortName}</h4>
                </>
            )
        } else {
            return(
                <>
                    <h1>{errorMessage}</h1>
                    <Link to = "/home">Go Home</Link>
                </>
            )
        }

    }

    return(
        <>
            {renderName()}
        </>
    )
}

