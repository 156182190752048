import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
// import MatchUpCard from "../MatchUpCard/MatchUpCard";
// import {MatchUp} from "../../service/matchUps";



const AltPositionCard = ({position, grads, deletePosition, handleSelect, handleRadio,handleCLickPosition}:any) => {

    const buttonCondition = (position: any) => {
        let condition = {string:"",variant:""}
        if (position !== null) {
            if (position.matchUps[0].grad !== null && position.matchUps[1].grad !== null) {
                condition.string = `assigned to ${position.matchUps[0].grad.name} & ${position.matchUps[1].grad.name}`
                condition.variant = "success"
            } else if (position.matchUps[0].grad === null && position.matchUps[1].grad !== null) {
                condition.string = `only assigned to ${position.matchUps[1].grad.name}`
                condition.variant = "warning"
            } else if (position.matchUps[0].grad !== null && position.matchUps[1].grad === null) {
                condition.string = `only assigned to ${position.matchUps[0].grad.name}`
                condition.variant = "warning"
            } else {
                condition.string = `select assignments`
                condition.variant = "danger"
            }
            return condition
        }

    }
    if(position !== null ){
        return (
            <Card style={{ width: '25rem', margin: '0.5rem' }}>
                <Card.Body>
                    <Card.Title style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Position id: {position.id}</span>
                        <Button variant="danger" size="sm" onClick={() => deletePosition(position.id)}>
                            X
                        </Button>
                    </Card.Title>
                        <Button
                            variant={buttonCondition(position)?.variant}
                            onClick={() => handleCLickPosition(position)}
                        >
                            {buttonCondition(position)?.string}
                        </Button>
                </Card.Body>
            </Card>
            )
    } else {
        return(<></>)
    }


}

export default AltPositionCard;