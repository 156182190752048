import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import PositionCard from "./PositionCard/PositionCard";
import * as teamLeadService from '../service/teamLeads';
import * as positionService from '../service/positions';
import * as gradService from '../service/grads';
import * as matchUpService from '../service/matchUps';
import {TeamLead} from "../service/teamLeads";
import { MatchUp } from "../service/matchUps";
import {Position} from "../service/positions";
import { Grad } from "../model/Grad";
import {Link, useParams} from "react-router-dom";
import MatchUpExport from "./MatchUpExport";
import {CohortNamePageHeader} from "./PageHeader/CohortNamePageHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import WarningModal from "./WarningModal";


function TeamLeads() {

    const params = useParams();
    const cohortId = Number(params.id);
    const gradPositionLimit = 2;

    const [teamLeads, setTeamLeads] = useState<TeamLead[]>([]);
    const [positions, setPositions] = useState<Position[]>([]);
    const [gradList, setGradList] = useState<Grad[]>([]);
    const availableGrads = findAvailableGrads(gradList, positions, gradPositionLimit);


    // Modal related code
    // const[showModal, setShowModal] = useState<boolean>(false);
    // const[message, setMessage] = useState<string>('');
    // const openModal = (message:string):void => {
    //     setShowModal(true);
    //     setMessage(message);
    // };
    //
    // const closeModal = ():void => {
    //     setShowModal(false);
    //     setMessage('');
    // };

    useEffect(() => {
        gradService.getAllCohortGrads(cohortId)
            .then(grads => setGradList([...grads]))
            .catch(error => toast.error(error));
        positionService.getAllCohortPositions(cohortId)
            .then(positions => setPositions([...positions]))
            .catch(error => toast.error(error));
        teamLeadService.getAllCohortTeamLeads(cohortId)
            .then(teamLeads => setTeamLeads([...teamLeads]))
            .catch(error => toast.error(error));
        // eslint-disable-next-line
    }, []);


    const handleDeleteTeamLead =  async (teamLeadId?: number) => {
        try {
            const deleted = await teamLeadService.deleteTeamLead(teamLeadId);
            if (deleted) {
                setTeamLeads([...teamLeads.filter((teamLead) => teamLead.id !== teamLeadId)]);
                toast.success('Deleted successfully!');
            }
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleAddPosition = async (teamLeadId: number) => {
        try {
            const newPosition = await positionService.addPosition(teamLeadId);
            setPositions([...positions, newPosition]);
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleDeletePosition = async (positionId: number) => {
        try {
            const deleted = await positionService.deletePosition(positionId);
            if(deleted) {
                setPositions([...positions.filter((position) => position.id !== positionId)]);
                toast.success('Deleted successfully!');
            }
        }
        catch(error: any) {
            toast.error(error);
        }
    }

    const handleChangeSelect = async (e: any, matchUpId:number) => {
        const gradId = parseInt(e.target.value);
        try {
            const updatedMatchUp = await matchUpService.updateGrad(matchUpId, gradId);
            setPositions([...positions.map(position => {
                if(position.id === updatedMatchUp.position?.id) {
                    position.matchUps = [...position.matchUps.map(matchUp => matchUp.id === updatedMatchUp.id ? updatedMatchUp : matchUp)];
                }
                return position;
            })]);
        }
        catch(error: any) {
            toast.error(error);
        }
    };

    const handleClickRadio = async (matchUp:MatchUp) => {
        if(!matchUp.grad?.id) {
            toast.warning('No grad chosen!');
            return;
        }
        try {
            const updatedMatchUps = await matchUpService.updatePreferred(matchUp.id);
            setPositions([...positions.map(position => {
                if(position.id === updatedMatchUps[0].position?.id) {
                    position.matchUps = [...updatedMatchUps];
                }
                return position;
            })]);
        }
        catch(error: any) {
            toast.error(error);
        }
    };


    return (
        <div>
            <CohortNamePageHeader></CohortNamePageHeader>
                <h1>Positions & Match Ups</h1>
            <Link  to={`/setup/${cohortId}`} className="btn btn-primary mt-3 mb-2" >
                <i className="bi bi-arrow-left"></i> Manage Cohort
            </Link>

                {teamLeads.map((teamLead) => (
                    <Card key={teamLead.id}>
                        <Card.Header>
                                <span>No. of Positions:
                                    <strong> {positions.filter(position => position.teamLead.id === teamLead.id).length}</strong>
                                    &nbsp; &nbsp;
                                    <Button variant="primary" size="sm" onClick={() => handleAddPosition(teamLead.id)}>
                                    <b>+</b> Add Position
                                    </Button>
                                </span>
                            <span>Team Lead: <strong>{teamLead.name}</strong></span>
                            <span>email: <strong>{teamLead.email}</strong></span>
                            <Button variant="danger" size="sm" onClick={() => handleDeleteTeamLead(teamLead.id)}>
                                X
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {positions.filter(position => position.teamLead.id === teamLead.id).map((position, index) =>
                                    <PositionCard
                                        key={position.id}
                                        position={position}
                                        availableGrads={availableGrads}
                                        grads={gradList}
                                        deletePosition={handleDeletePosition}
                                        handleSelect={handleChangeSelect}
                                        handleRadio={handleClickRadio}
                                    />
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}

            <MatchUpExport/>
            <Link to={`/solutions/${cohortId}`} className="btn btn-primary mx-3 mt-3 mb-5 float-end">
                Solutions <i className="bi bi-arrow-right"></i>
            </Link>
            {/*<WarningModal showModal={showModal} closeModal={closeModal} message={message}/>*/}
        </div>

    )
}
const findAvailableGrads = (gradList: Grad[], positions: Position[], maxCount: number) =>{
    let gradMap: Map<number, number> = new Map();
    positions.forEach((position) =>{
        position.matchUps.forEach((matchUp : MatchUp)=> {
            if ((matchUp.grad?.id)) {
                const id = matchUp.grad.id;
                if (gradMap.has(id)) {
                    gradMap.set(id, gradMap.get(id)! + 1);
                } else {
                    gradMap.set(id, 1);
                }
            }
        });
    });
    // console.log(gradMap);
    //once we fill the map, we will make a new array of the items that have less than the maxCount.
    return gradList.filter((grad)=>{
        return !(gradMap.has(grad.id) && gradMap.get(grad.id)! >= maxCount);
    });
}
export default TeamLeads;