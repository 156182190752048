
async function getAuthentication() : Promise<number>{

    let stat = -1;
    const token = sessionStorage.getItem('X-Auth-Token')!;
    
     await fetch (`/api/authenticate`, {
        
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            token:token
        }),
    }).then(response => {
        stat = response.status;
    })
  
    .catch(error => {
        console.log(error);
        alert(error);
        stat = 500;
    });
    return stat;
}
export {getAuthentication}