import { Button, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

function CohortCard(props: any) {

    return (
        <div className="card-deck">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span> {props.cohort.name}</span>
                        <Button variant="danger" size="sm" onClick={() => { props.deleteCohort(props.cohort.id) }}>X</Button>
                    </h5>
                    <p className="card-text">
                        {props.cohort.description}
                    </p>

                    <div style={{ display: "flex" }}>
                        <div className="" style={{ flex: "1" }}>
                            <Stack direction="horizontal" gap={2}>
                                <Button onClick={() => { props.editCohort(props.cohort) }} className="btn btn-primary">
                                    Edit
                                </Button>
                                <Link to={`/setup/${props.cohort.id}`} className="btn btn-primary">
                                    Manage
                                </Link>
                                <Link to={`/solutions/${props.cohort.id}`} className="btn btn-primary">
                                    Solutions
                                </Link>
                            </Stack>
                        </div>
                        <div className="">
                            <p>Total Graduates: <strong>{props.cohort.totalGraduates}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CohortCard;