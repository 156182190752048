import { Button, Stack, Table } from "react-bootstrap";
import clipBoardCSV from "../../utils/ClipBoardCSV";
import jwtFetch from "../../utils/JwtFetch";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grad } from "../../model/Grad";
import React from "react";

export const GradsTable = (props: any) => {
    const { id } = useParams();
    const copyLeadstoClipBoard = async () => {

        try {
            const response = await jwtFetch(`/api/grads/export/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                await clipBoardCSV(response);
                toast.success("CSV copied to clipboard")
            } else {
                console.error('File copy failed:', response.statusText);
                toast.error('File copy failed:' + response.statusText)
            }
        } catch (error) {
            console.error('Error copying file:', error);
            toast.error('Error copying file:' + error)
        }


    }
    function editGrad(grad: Grad, event: any) {
        props.handleMode('edit');
        props.handleFormData(grad);
        props.openModal();
    }

    function addNew(event: any) {
        props.handleMode('create');
        props.openModal();
    }

    return (
        <>
            <h1>Grads</h1>
            <div className="d-flex flex-row-reverse bd-highlight">
                <div className="p-2 bd-highlight">
                    <Button variant="primary" onClick={copyLeadstoClipBoard}>
                        <i className="bi bi-filetype-csv"></i> Copy Csv
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="secondary" onClick={props.exportGrads}>
                        <i className="bi bi-filetype-csv"></i> Export
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="secondary" onClick={props.importGrads}>
                        <i className="bi bi-filetype-csv"></i> Import
                    </Button>
                </div>
                <div className="p-2 bd-highlight">
                    <input
                        type="file"
                        className="form-control"
                        onChange={props.handleFileChange}
                    />
                </div>
                <div className="p-2 bd-highlight">
                    <Button variant="primary" onClick={addNew}> <b>+</b> Add New</Button>
                </div>
            </div>
            <div className="scrollable-container">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th style={{ width: "20%" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.gradList.length === 0 ? (
                            <tr>
                                <td colSpan={4}><span>No records found</span></td>
                            </tr>
                        ) : (
                            props.gradList.map((grad: any, index: any) => (
                                <tr key={grad.id}>
                                    <td>{++index}</td>
                                    <td>{grad.name}</td>
                                    <td>{grad.email}</td>
                                    <td>
                                        <Stack direction="horizontal" gap={2}>
                                            <Button variant="primary" size="sm"
                                                onClick={(event: any) => { editGrad(grad, event) }}
                                            >
                                                Edit
                                            </Button>
                                            <Button variant="danger" size="sm"
                                                onMouseOver={() => { props.setSelectedGradId(grad.id) }}
                                                onClick={props.deleteGrad}>Delete
                                            </Button>
                                        </Stack>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}